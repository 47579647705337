<template>
<div>
  <div>
    <div class="text-xs-center" v-if='loading'>
        <v-progress-circular
          :size="250"
          :width="10"
          color="indigo"
          indeterminate
          >Loading Data...
        </v-progress-circular>
    </div>
    <v-flex xs12 _md8 _offset-md2 v-else>
      <page-title>My Fees</page-title>
      <v-layout class="mb-4" row wrap>
          <v-flex xs12 md3>
            <v-card elevation="1" class="animate__animated animate__slideInUp">
                <v-card-text class="text-capitalize">
                <span class="caption">TOTAL FEE</span> <h4 class="primary--text title pl-1 pt-2"> Rs. {{data.total_fee | numberFormat}}	</h4>
                <!-- &#8377; -->
                </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md3>
            <v-card elevation="1" class="animate__animated animate__slideInUp">
                <v-card-text>
                  <span class="caption">TOTAL FEE PAID</span> <h4 class="primary--text title pl-1 pt-2"> Rs. {{data.total_paid_fee | numberFormat}}</h4>
                </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md3>
            <v-card elevation="1" class="animate__animated animate__slideInUp">
                <v-card-text>
                  <span class="caption">TOTAL BALANCE</span> <h4 class="primary--text title pl-1 pt-2"> Rs. {{data.balance | numberFormat}}</h4>
                </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 md3>
            <v-card elevation="1" class="animate__animated animate__slideInUp">
                <v-card-text>
                  <span class="caption">ADVANCE FEE</span> <h4 class="primary--text title pl-1 pt-2"> Rs. {{data.advance_fee | numberFormat}}</h4>
                </v-card-text>
            </v-card>
          </v-flex>
      </v-layout>
        <v-layout>
          <v-flex xs12>
              <v-data-table
                v-model="selected"
                :headers="headers"
                :items="feeList"
                item-key="_id"
                :rows-per-page-items="[10, 20, 50]"
                class="elevation-1"
                :select-all="true" >
                <template v-slot:items="props">
                  <td>
                    <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
                  </td>
                  <td ><a @click="dialog=true; fee = props.item">{{ props.item | feeName }} <v-icon>open_in_new</v-icon></a></td>
                  <td >{{ props.item.due_date | timeFormatHuman }}</td>
                  <td >{{ parseFloat(props.item.fee_amount).toFixed(2) }}</td>
                  <td >{{ props.item.pay_amount | numberFormat }} <i v-if="props.item.fine_amount>0" class="red--text"><br/>+ {{props.item.fine_amount | numberFormat}} (Fine)</i></td>
                  <td >{{ parseFloat(props.item.balance).toFixed(2) }}</td>
                  <td :class="(props.item.payment_status==='paid'?'success--text':'warning--text') + ' text-xs-center'"><v-chip :color="props.item | colorBgChip" :text-color="props.item | colorTextChip">{{ props.item.payment_status.toUpperCase() }}</v-chip></td>
                  <td class="text-xs-center">
                    <v-btn small round outline color="primary" @click="feePaymentItem = props.item; paymentDialog = true;" :disabled="props.item.payment_status==='paid'">PAY ONLINE</v-btn>
                    <!-- <v-btn small round outline @click="feePaymentItem = props.item; receiptDialog = true;"> Upload Receipt</v-btn> -->
                    <v-btn small round outline @click="printReceiptRequest(props.item.fee_head_id, props.item._id, props.item.student_id)"> Print Receipt</v-btn>
                  </td>
                </template>
            </v-data-table>
          </v-flex>
        </v-layout>
        <!-- <v-btn :disabled="!selected.length" color="primary">Pay Selected</v-btn> -->
    </v-flex>
    </div>

    <PaymentConfigVue :payment-dialog="paymentDialog" :fee-item="feePaymentItem" @close="paymentDialog = false; feePaymentItem = null"></PaymentConfigVue>
    <UploadReceiptVue :receipt-dialog="receiptDialog" :fee-item="feePaymentItem" @close="receiptDialog = false; feePaymentItem = null"></UploadReceiptVue>
    
    <v-dialog v-model="dialog" width="500" :scrollable="true">
     <v-card v-if="fee">
        <v-card-title class="headline grey lighten-2"  primary-title> {{fee | feeName}} </v-card-title>
        <v-card-text class="pb-4">
          <v-list>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Due Date: <b>{{fee.due_date  | timeFormatHuman}}</b></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
         
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Total Amount: <b>Rs. {{fee.fee_amount | numberFormat}}</b></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Paid Amount: <b>Rs. {{fee.pay_amount | numberFormat}}</b></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Fine Amount: <b>Rs. {{fee.fine_amount | numberFormat}}</b></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Balance Amount: <b>Rs. {{fee.balance | numberFormat}}</b></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>Payment Status: <v-chip small :color="fee | colorBgChip" :text-color="fee | colorTextChip">{{ fee.payment_status.toUpperCase() }}</v-chip></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

          </v-list>
          
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="primary" flat @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingPrint" fullscreen :scrollable="true" persistent>
      <v-card>
        <v-card-text>
          <v-progress-circular :size="200" :width="7" color="indigo" indeterminate>Loading please wait...</v-progress-circular>
          <!-- <h4>Loading please wait...</h4> -->
        </v-card-text>
      </v-card>
      
    </v-dialog>

</div>
</template>

<script>
const {instance} = require('./../../_axios.js');
import moment from 'moment';
import PrintPDF from '../../components/PrintPDF';
import PaymentConfigVue from './PaymentConfig.vue';
import UploadReceiptVue from './UploadReceipt.vue';

export default {
    data() {
        return {
            loading: true,
            data: {},
            feeList: [],
            selected: [],
            fee: null,
            dialog: false,
            paymentDialog: false,
            receiptDialog: false,
            feePaymentItem: null,
            loadingPrint: false,
            headers: [
                { text: 'Fee Details', sortable: false, value: 'fee_type_data' },
                { text: 'Due Date', sortable: false, value: 'fee_type_data' },
                { text: 'Total Amt. (Rs)', sortable: false, value: 'fee_type_data' },
                { text: 'Paid (Rs)', sortable: false, value: 'fee_type_data' },
                { text: 'Balance (Rs)', sortable: false, value: 'fee_type_data' },
                { text: 'Status', sortable: false, value: 'fee_type_data', align: 'center' },
                { text: 'Action', sortable: false, align: 'center'},
            ]
        }
    },
    mounted(){
        this.load()
    },
    methods: {  
        async load(){
            try {
                let ADMINAPI = process.env.VUE_APP_ADMINAPI || ''
                this.loading = true
                let resp = await instance.get(`${ADMINAPI}/fee-list`)
                this.data = resp.data
                if(this.data) this.feeList = [].concat(this.data.fee_collections || []).reverse()
            } catch (error) {
                console.log(error.message)
            } finally{
                this.loading = false
            }
        },
        async printReceiptRequest(head_id, student_fee_id, student_id){
          console.log(head_id, student_fee_id, student_id)
          try {
            this.loadingPrint = true
            // instance.get(`http://localhost:8000/fee-head/${head_id}/print?student_fee_id=${student_fee_id}&student_id=${student_id}`)
            let resp = await instance.get(`${process.env.VUE_APP_ADMIN}/fee-head/${head_id}/print?student_fee_id=${student_fee_id}&student_id=${student_id}`)
            console.log(resp)
            PrintPDF(resp.data)
          } catch (error) {
            alert("Error : "+error.message)
          } finally{
            this.loadingPrint = false
          }
        }
    },
    filters: {
      timeFormatHuman(val){
        return moment(val).format('Do MMM Y')
      },
      timeFormat(val){
        return moment(val).format('DD-MM-Y')
      },
      numberFormat(val){
        return parseFloat(val).toFixed(2)
      },
      colorTextChip(val){
        return val.payment_status == 'paid' || val.overdue == true ? 'white' : ''
      },
      colorBgChip(val){
        return val.payment_status == 'paid'? 'green' : ( val.overdue == true ? 'red' : '')
      },
      feeName(val){
        return val.fee_head_title + ([ 'tuition', 'transport', 'hostel' ].indexOf(val.fee_type) >= 0 ? ` (${ moment(val.due_month).format('MMM YY') })`:'')
      }
    },
    components: {
      PaymentConfigVue,
      UploadReceiptVue
    }
}
</script>